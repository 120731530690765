import React from 'react';
import Layout from '../components/layout';
import { Navigation } from '../components/navigation';

export default () => (
  <Layout>
    <Navigation fixed />
    <div className="section">
      <div className="container is-max-desktop">
        <h3 className="subtitle">fakeOS</h3>
        <h2 className="title">Impressum</h2>

        <p>
          <strong>fakeOS</strong>
          <br />
          I Love Computers &amp; Internet
          <br />
          Domstr. 91
          <br />
          50668 Köln
          <br />
          <a href="mailto:support@fakeos.de">support@fakeos.de</a>
        </p>

        <h3>Haftungshinweis/ Haftungsausschluß</h3>
        <p>
          Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
          für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten
          sind ausschließlich deren Betreiber verantwortlich. Wir distanzieren
          uns ausdrücklich von allen verlinkten Seiten. Alle Angaben erfolgen
          ohne Gewähr. Alle Angebote sind freibleibend.
          <br />
          Alle Rechte vorbehalten.
        </p>
      </div>
    </div>
  </Layout>
);
